<h3>ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</h3>

<br/>
<h4>ΧΩΡΟΣ ΔΙΕΞΑΓΩΓΗΣ</h4>
<div>Πλανητάριο Αθηνών, Αμφιθέατρο.</div>

<h4>ΧΡΟΝΟΣ ΔΙΕΞΑΓΩΓΗΣ</h4>
<div>1-3 Δεκεμβρίου 2022</div>

<h4>ΚΟΣΤΟΣ ΣΥΜΜΕΤΟΧΗΣ</h4>
<div>Η εγγραφή στο Συνέδριο για ΕΥ (Επαγγελματίες Υγείας) έχει ως εξής:</div>
<br/>
<div>ΕΙΔΙΚΟΙ ΟΦΘΑΛΜΙΑΤΡΟΙ   <b style="margin-left:43px">80,00 €</b></div>
<div>ΕΙΔΙΚΕΥΟΜΕΝΟΙ          <b style="margin-left:100px">50,00 €</b></div>
<br/>
<div>Για τις λοιπές κατηγορίες, όπως :</div>
<div>Οπτομέτρες, φοιτητές, νοσηλευτές<b style="margin-left:8px">ΔΩΡΕΑΝ</b></div>
<br/>
<div style="font-style:italic">Κατά την τιμολόγηση το παραπάνω κόστος εγγραφής επιβαρύνεται με 24% ΦΠΑ.</div>

<h4>ΑΚΥΡΩΣΕΙΣ</h4>
<div>Το δικαίωμα συμμετοχής στο συνέδριο δεν επιστρέφεται.</div>

<h4>ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ</h4>
<div>-Εως το συνέδριο:</div>
<div>Κατάθεση ποσού στην Τράπεζα Πειραιώς, σε διαταγή:</div>
<div><b>ΕΛΛΗΝΙΚΗ ΕΤΑΙΡΕΙΑ ΞΗΡΟΦΘΑΛΜΙΑΣ & ΟΦΘΑΛΜΙΚΗΣ ΕΠΙΦΑΝΕΙΑΣ</b></div>
<div><b>Αριθμός Λογαριασμού </b></div>
<div><b>ΙΒΑΝ GR82 0172 0450 0050 4509 6562 332  </b></div>
<div>αναφέροντας ονοματεπώνυμο και ιδιότητα συνέδρου (ειδικευμένος/ειδικευόμενος)</div>
<div>-Κατά τη διάρκεια του συνεδρίου:</div>
<div>Πληρωμή στη γραμματεία των εγγραφών</div>

<h4>ΠΛΗΡΟΦΟΡΙΕΣ ΕΓΓΡΑΦΗΣ</h4>
<div>Η συμμετοχή στο Συνέδριο, περιλαμβάνει δυνατότητα παρακολούθησης του επιστημονικού προγράμματος, δυνατότητα απομακρυσμένης παρακολούθησης (live streaming), είσοδο στην έκθεση, συμμετοχή στις κοινωνικές εκδηλώσεις του Συνεδρίου (διαλείμματα καφέ & φαγητού), καθώς και παραλαβή του ηλεκτρονικού πιστοποιητικού συμμετοχής, βάσει των ωρών παρακολούθησης.</div>
<div>Για νοσηλευτές/φοιτητές, η συμμετοχή στο Συνέδριο είναι δωρεάν και περιλαμβάνει δυνατότητα παρακολούθησης του επιστημονικού προγράμματος, είσοδο στην έκθεση, και απλή βεβαίωση συμμετοχής. Η ιδιότητα τους ωστόσο, θα πρέπει να βεβαιώνεται κατά την εγγραφή τους, με έγγραφο επίσημου φορέα που θα πιστοποιήσει την ιδιότητα τους (πχ επιστολή από προϊστάμενο, διευθυντή, καθηγητή κλπ)</div>
<div>Η εγγραφή των συνέδρων γίνεται με ηλεκτρονικό σύστημα, βάσει του ατομικού QR code που θα αποστέλλεται στο email κάθε εγγεγραμμένου συνέδρου.</div>
<div>H αρχική διαδικασία εγγραφής στη γραμματεία προϋποθέτει τη χρήση smartphone, και η συνέχεια τυπωμένο QR code στην καρτέλα που θα λαμβάνει ο σύνεδρος. Το ίδιο QR code θα χρησιμοποιείται για την είσοδο στις αίθουσες και τα διαλείμματα καφέ, και για την τελική αξιολόγηση και παραλαβή πιστοποιητικού.</div>

<h4>ΑΥΤΟΜΑΤΗ ΜΕΤΑΦΡΑΣΗ - ΜΟΡΙΟΔΟΤΗΣΗ</h4>
<div>Καθ’ όλη τη διάρκεια του Συνεδρίου θα υπάρχει ταυτόχρονη μετάφραση των ομιλιών από αγγλικά στα ελληνικά, καθώς και σύστημα ηλεκτρονικής καταμέτρησης των ωρών παρακολούθησης του προγράμματος. </div>
<div>Το Συνέδριο μοριοδοτείται με μόρια συνεχιζόμενης εκπαίδευσης, και η παραλαβή του πιστοποιητικού προϋποθέτει τη συμπλήρωση -ηλεκτρονικά- του ανώνυμου εντύπου αξιολόγησης και την παρακολούθηση του 60% των ωρών του επιστημονικού προγράμματος, κατ’ ελάχιστον.</div>

<h4>ΓΡΑΜΜΑΤΕΙΑ ΣΥΝΕΔΡΙΟΥ</h4>
<div>Η Γραμματεία θα λειτουργεί καθ’ όλη τη διάρκεια διεξαγωγής του συνεδρίου, ως εξής:</div>
<br/>
<table>
    <tr><td>ΠΕΜΠΤΗ</td><td style="padding-left:8px">01/12/2022</td><td style="padding-left:8px">14.00 – 20.00</td></tr>
    <tr><td>ΠΑΡΑΣΚΕΥΗ</td><td style="padding-left:8px">02/12/2022</td><td style="padding-left:8px">08.30 – 20.00</td></tr>
    <tr><td>ΣΑΒΒΑΤΟ</td><td style="padding-left:8px">03/12/2022</td><td style="padding-left:8px">08.30 – 20.00</td></tr>
</table>
<!-- <div>ΠΕΜΠΤΗ 01/12/2022: 14.00 – 20.00</div>
<div>ΠΑΡΑΣΚΕΥΗ 02/12/2022: 09.00 – 20.00</div>
<div>ΣΑΒΒΑΤΟ 03/12/2022: 09.00 - 20.00</div> -->

<h4>ΣΥΝΕΔΡΙΑΚΕΣ ΑΙΘΟΥΣΕΣ & ΧΩΡΟΣ ΕΚΘΕΣΗΣ </h4>
<div>Το συνέδριο θα πραγματοποιηθεί στο Κεντρικό Αμφιθέατρο του Πλανηταρίου Αθηνών.</div>
<div>Ο χώρος έκθεσης των εταιρειών του κλάδου, θα λειτουργήσει στο Περιστύλιο και στις  παράπλευρες αίθουσες του Αμφιθεάτρου.</div>

<h4>ΔΙΑΜΟΝΗ ΣΥΝΕΔΡΩΝ - ΠΛΗΡΟΦΟΡΙΕΣ ΚΡΑΤΗΣΗΣ ΔΩΜΑΤΙΩΝ </h4>
<div>Επισημαίνεται ότι η Ελληνική Εταιρεία Ξηροφθαλμίας και Οφθαλμικής Επιφάνειας δεν θα προβεί σε κρατήσεις δωματίων για τους επαγγελματίες υγείας (ΕΥ). Οι ενδιαφερόμενοι θα πρέπει να μεριμνήσουν σχετικά, ενώ σε περίπτωση κάλυψης διαμονής των ΕΥ από φαρμακευτικές εταιρείες, θα πρέπει να λαμβάνεται υπόψη ότι το κόστος φιλοξενίας (διαμονή και διατροφή) των ΕΥ δεν μπορεί να υπερβαίνει τα ποσά που ορίζουν οι ισχύουσες εγκύκλιοι ΕΟΦ και ΣΦΕΕ.</div>

