<mat-nav-list>
    <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon><span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="/info" (click)="onSidenavClose()">
        <span class="nav-caption">Πληροφορίες συμμετοχής</span>
    </a>
    <a mat-list-item routerLink="/registration" (click)="onSidenavClose()">
        <span class="nav-caption">Φόρμα εγγραφής</span>
    </a>
    <a mat-list-item routerLink="/live" (click)="onSidenavClose()">
        <span class="nav-caption">Live Streaming</span>
    </a>
    <a mat-list-item routerLink="/sponsors" (click)="onSidenavClose()">
        <span class="nav-caption">Χορηγοί</span>
    </a>
</mat-nav-list>