<!-- <section fxLayout="column" fxFlexAlign="stretch"> 
    <div fxFlexAlign="center"> 
        <p>Welcome to the Material Angular OwnerAccount Application</p> 
    </div>

    <p>In this application we are going to work with:</p> 

    <mat-tab-group (selectedTabChange)="executeSelectedChange($event)">
        <mat-tab label="Material Components"> 
          <p>We are going to use different material components to create nice looking angular app.</p> 
        </mat-tab>
        <mat-tab label="Consume .NET Core Web API">
           <p>We will consume our .NET Core application. Basically, we will create complete CRUD client app.</p>
          </mat-tab>
        <mat-tab label="Fully responsive navigation menu">
           <p>By using material components, we are going to create fully responsive navigation menu, with its side-bar as well.</p>
          </mat-tab>
      </mat-tab-group>
</section> -->

<!-- <div style="margin-top: 58px;">
  <div class="container"> -->
    <section fxLayout="column" fxFlexAlign="stretch"> 
      <table>
          <tr>
             <td style="vertical-align: top;width: 30%;">
                 <img src="../../assets/afisa.png" width="100%" />
             </td> 
             <td style="vertical-align: top;padding-left: 12px;">
                 <div>
                    <h2>On Demand παρακολούθηση</h2>
                    <p>Για on demand παρακολούθηση, πατάτε το κουμπί "OnDemand video" ή <a href="https://conf.letscongress.eu/home/1393">εδώ</a> και αφού κάνετε login ( με το email σας και το ΡΙΝ που θα σας έρθει ) , επιλέγετε PROGRAM. Δίπλα από κάθε τραπέζι θα δείτε μια κάμερα. Με click πάνω της βλέπετε το video. Προσοχή: Χωρίς το login δεν εμφανίζεται η κάμερα.</p>
                    <br/> 
                     <h2>Χαιρετισμός Προέδρου</h2>
                     <p>Αγαπητοί συνάδελφοι, αγαπητοί συνεργάτες</p>
                     <br/>
                     <p>Τρία χρόνια μετά το πρώτο επιτυχημένο -κατά γενική ομολογία- Συνέδριο της Ελληνικής Εταιρείας Οφθαλμικής Eπιφάνειας & Ξηροφθαλμίας, και ξεπερνώντας την ασφυκτική πίεση που έχουμε όλοι δεχτεί από την Covid19 και τους περιορισμούς που επιβλήθηκαν, συναντιόμαστε και πάλι για το δεύτερο Συνέδριο της Εταιρείας.</p>
                     <p>Αυτή τη χρονιά μας υποδέχεται το Ίδρυμα Ευγενίδου, φιλοξενώντας μας σε έναν χώρο φωτεινό και χαρούμενο αλλά και με μια ξεχωριστή αύρα, όπως αυτή διαχέεται μέσα από τις προβολές του Πλανηταρίου του, καθώς ξετυλίγει τα μυστήρια του «κόσμου». Και όπως ανακαλύπτουμε τον κόσμο σε βάθος, ανακαλύπτουμε και τα δάκρυα, γιατί πρέπει να αποκαλύψουμε τα μυστικά τους για να μας γίνουν πιο οικεία.</p>
                     <p>Οι συμβολισμοί της θεματολογίας του συνεδρίου συνεχίζονται με το λογότυπό μας. Ας κάνουμε προσπάθεια να προστατέψουμε τις θάλασσές μας από τα πλαστικά, τα απόβλητα, από εμάς τους ίδιους, και αντίστοιχα, ας προσπαθήσουμε να προστατέψουμε τους ασθενείς μας από τα ποικίλα προβλήματα των δακρύων, από την υπερθεραπεία και την ελλιπή αντιμετώπιση.</p>
                     <p>Στο συνέδριο αυτό, θα επιδιώξουμε να αναλύσουμε σε βάθος αλλά και να αυξήσουμε την κατανόησή μας για την πρόληψη και την ορθή προσέγγιση των παθήσεων της οφθαλμικής επιφάνειας, ώστε να έχουμε χαρούμενους ασθενείς και να γεμίσουμε αυτοπεποίθηση και αισιοδοξία για ένα καλύτερο αύριο.</p>
                     <p>Σας περιμένουμε όλους από κοντά για να ξαναβρεθούμε και να μοιραστούμε εμπειρίες και γνώσεις, παρέα με εκλεκτούς συναδέλφους από την Ελλάδα και το εξωτερικό! </p>
                     <br/>
                     <div><img src="..\..\assets\images\terzidou.jpg" /></div>
                 </div>
             </td>
          </tr>
      </table>
      </section>
      
    <!-- </div>
</div> -->