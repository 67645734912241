<mat-toolbar >
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="home">
        <a routerLink="/home">Home</a>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <a routerLink="/info">Πληροφορίες</a>
            </li>
            <!-- <li>
                <a routerLink="/registration">Φόρμα εγγραφής</a>
            </li> -->
            <li>
                <a routerLink="/live">OnDemand video</a>
            </li>
            <li>
                <a routerLink="/sponsors">Χορηγοί</a>
            </li>
        </ul>
    </div>
</mat-toolbar>